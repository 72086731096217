@charset "utf-8";

.conteudo
{
	height: 100vh;
	width: 100vw;
}

.Header_faixa
{
	display: flex;
	justify-content: right;
}

.BG_Header
{
	background-color : #0057aa;
	border-style: solid;
	border-width: 0px 0px 7px 0px;
	border-color: #ffd300;
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
	height: 17%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.BG_Miolo
{
	background : #E6EAF0;
	background : rgba(230, 234, 240, 1);
	height: 77%;
}
.Footer
{
	background-color : #0057aa;
	height: 5%;
}

.Text_area
{
	align-items: center;
	display: flex;
	flex-direction: column;
	text-decoration: none;
}



.Erro {
	color: red;
	font-weight: 600;
	background-color: rgba(255, 0, 0, 0.3);
	width: 100%;
	padding: 4px;
}

a {
	text-decoration: none;
	color: rgba(39, 118, 180, 1);
}


.centralizar {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -5%;
	margin-top: -5%;
}

@media only screen and (min-width: 1024px) {
	.voltar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		cursor: pointer;
	}

	.Botao_voltar {
		background-image : url(../../../public/assets/Header_faixa.png);
		background-repeat : no-repeat;
		background-size: 18px 18px;
		height: 18px;
		width: 18px;
		margin-right: 4px;
	}

	.Titulo
	{
		display: flex;
		align-items: center;
		width: 228px;
		justify-content: space-between;
		line-height: 0;
	}

	p {
		width: 400px;
	}
	
	.Login_area
	{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -120px;
		margin-left: -230px;
		border-radius: 10px;
		background-color: white;
		height: 280px;
		width: 450px;
		color: rgba(39, 118, 180, 1);
		padding: 8px;
		border-bottom: 24px solid #0057aa;
		-webkit-box-shadow: 2px 5px 18px 8px rgba(0,0,0,0.42); 
		box-shadow: 2px 5px 18px 8px rgba(0,0,0,0.42);
		font-size: 14px;
	}

	.Logo
	{
		background-image : url(../../../public/assets/Logo.png);
		background-repeat : no-repeat;
		background-size: 56px 24px ;
		height: 24px;
		width: 56px;
	}

	.Logo_pmu
	{
		background-image : url(../../../public/assets/Logo_pmu.png);
		background-repeat : no-repeat;
		background-size: 308px 50px ;
		height: 50px;
		width: 308px;
	}


	.Botao
	{
		background-image : url(../../../public/assets/Botao.png);
		background-repeat : no-repeat;
		background-size: 124px 44px;
		width: 124px;
		height: 44px;
		cursor: pointer;
		margin: 8px;
	}


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.voltar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		cursor: pointer;
	}

	.Botao_voltar {
		background-image : url(../../../public/assets/Header_faixa.png);
		background-repeat : no-repeat;
		background-size: 14px 14px;
		height: 14px;
		width: 14px;
		margin-right: 4px;
	}
	
	p {
		width: 300px;
	}
	.Titulo
	{
		display: flex;
		align-items: center;
		width: 196px;
		justify-content: space-between;
		line-height: 0;
	}

	.Login_area
	{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -120px;
		margin-left: -180px;
		border-radius: 10px;
		background-color: white;
		height: 250px;
		width: 350px;
		color: rgba(39, 118, 180, 1);
		padding: 8px;
		border-bottom: 24px solid #0057aa;
		-webkit-box-shadow: 2px 5px 18px 8px rgba(0,0,0,0.42); 
		box-shadow: 2px 5px 18px 8px rgba(0,0,0,0.42);
		font-size: 12px;
	}

	.Logo
	{
		background-image : url(../../../public/assets/Logo.png);
		background-repeat : no-repeat;
		background-size: 46px 18px ;
		height: 18px;
		width: 46px;
	}

	.Logo_pmu
	{
		background-image : url(../../../public/assets/Logo_pmu.png);
		background-repeat : no-repeat;
		background-size: 308px 50px ;
		height: 50px;
		width: 308px;
	}

	.Botao
	{
		background-image : url(../../../public/assets/Botao.png);
		background-repeat : no-repeat;
		background-size: 124px 44px;
		width: 124px;
		height: 44px;
		cursor: pointer;
		margin: 8px;
	}


}

@media only screen and (max-width: 768px) {
	.voltar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		cursor: pointer;
	}

	.Botao_voltar {
		background-image : url(../../../public/assets/Header_faixa.png);
		background-repeat : no-repeat;
		background-size: 14px 14px;
		height: 14px;
		width: 14px;
		margin-right: 4px;
	}

	.Titulo
	{
		display: flex;
		align-items: center;
		width: 166px;
		justify-content: space-between;
		line-height: 0;
	}

	p {
		width: 250px;
	}
	
	.Login_area
	{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -100px;
		margin-left: -150px;
		border-radius: 10px;
		background-color: white;
		height: 200px;
		width: 300px;
		color: rgba(39, 118, 180, 1);
		padding: 6px;
		border-bottom: 24px solid #0057aa;
		-webkit-box-shadow: 2px 5px 18px 8px rgba(0,0,0,0.42); 
		box-shadow: 2px 5px 18px 8px rgba(0,0,0,0.42);
		font-size: 10px;
	}

	.Logo
	{
		background-image : url(../../../public/assets/Logo_mobile.png);
		background-repeat : no-repeat;
		background-size: 38px 15px ;
		height: 15px;
		width: 38px;
	}

	.Logo_pmu
	{
		background-image : url(../../../public/assets/Logo_pmu.png);
		background-repeat : no-repeat;
		background-size: 200px 45px ;
		height: 45px;
		width: 200px;
	}

	.Botao
	{
		background-image : url(../../../public/assets/Botao.png);
		background-repeat : no-repeat;
		background-size: 96px 32px;
		width: 96px;
		height: 32px;
		cursor: pointer;
		margin: 6px;
	}


}